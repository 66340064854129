<template>
  <!-- This component receives the image files and handles their order & display, then sends the current final list up -->
  <div>
    <!-- SIMPLE SELECT - MOBILE -->
    <div v-show="$vuetify.breakpoint.mobile">
      <v-row>
        <v-col cols="12">
          <v-btn block @click="addImages = !addImages"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Images
          </v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-file-input
              ref="fileInput"
              v-show="addImages"
              class="rounded-lg mt-n1"
              flat
              label="Click me to choose an image!"
              show-size
              solo
              @change="onSelectedFile"
          />
        </v-col>
      </v-row>
    </div>

    <!-- DROPZONE - DESKTOP -->
    <div v-show="!$vuetify.breakpoint.mobile" class="main-box rounded-lg mb-3">
      <!-- Actual File Upload -->
      <div
          :class="{ 'drag-area-active': isDragOver }"
          class="drag-area border-radius"
          @click="$refs.file.$refs.input.click()"
          @drop.prevent="dropHandler($event)"
          @dragover.prevent="isDragOver = true"
          @dragleave.prevent="isDragOver = false"
      >
        <p class="text-body-1">Drag and drop files or click here!</p>
        <v-icon size="75">mdi-plus</v-icon>
        <v-file-input
            ref="file"
            class="d-none"
            @change="onSelectedFile"
        />
      </div>
    </div>

    <v-alert
        v-if="isError"
        dismissible
        type="error"
        @click="isError = false"
    >{{ errMsg }}
    </v-alert>

    <!-- DISPLAY (LIST) - BOTH -->
    <v-row>
      <v-col v-for="(img, index) in images" :key="index" cols="12" lg="4" md="4">
        <v-card class="my-1 rounded-lg">
          <v-img :src="viewImage(img)" height="300"/>
          <v-row class="pa-3 pb-0">
            <v-col>
              <p>{{ img.name }}</p>
            </v-col>
            <v-spacer/>
            <v-col>
              <v-btn class="rounded-pill float-right" outlined @click="deleteImage(img)">
                <v-icon color="primary">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Compressor from 'compressorjs';

export default {
  name: 'FileUpload',
  props: {
    givenImages: {
      type: Array,
      default: () => [],
    },
  },
  components: {Compressor},
  data() {
    return {
      imgNum: 0,
      maxImgs: 5,
      images: [],
      isDragOver: false,
      addImages: false,
      isError: false,
      errMsg: ""
    };
  },
  mounted() {
    this.images = this.givenImages;
  },
  watch: {
    images: function () {
      this.$emit("updateImages", this.images)
    }
  },
  methods: {
    viewImage(imageBlob) {
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(imageBlob);
    },
    async compressImage(original) {
      let doc = this;
      await new Compressor(original, {
        quality: 0.6,
        success(compImg) {
          if (compImg instanceof Blob) {
            compImg = new File([compImg], compImg.name, {type: compImg.type, lastModified: Date.now()})
          }
          doc.images.push(compImg);
        },
        error(err) {
          console.error("Compression Error: " + err.message);
          this.isError = true;
          this.isError = "There was an error compressing your image(s)."
        },
      });
    },
    dropHandler(file) {
      const droppedFiles = file.dataTransfer.files;
      for (let i = 0; i < droppedFiles.length; i++) {
        this.imgNum++;
        if (this.imgNum > this.maxImgs) {
          this.imgNum--;
          this.isError = true;
          this.errMsg = "You have provided too many images, the maximum amount is " + this.maxImgs + ".";
          break;
        }
        this.compressImage(droppedFiles[i]);
      }
      this.isDragOver = false;
    },
    deleteImage(target) {
      this.imgNum--;
      this.images = this.images.filter((file) => file !== target);
    },
    async onSelectedFile(file) {
      this.imgNum++;
      if (this.imgNum > this.maxImgs) {
        this.imgNum--;
        this.isError = true;
        this.errMsg = "You have provided too many images, the maximum amount is " + this.maxImgs + ".";
        return;
      }
      await this.compressImage(file);
      this.addImages = false;
      this.$refs.fileInput.value = null;
    }
  },
};
</script>

<style>
.border-radius {
  border-radius: 25px;
}

.main-box {
  background-color: #fcfcfc;
  width: 100%;
  height: 200px;
}

.drag-area {
  width: 90%;
  height: 90%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  transition: background-color 0.5s;
  background-color: #fcfcfc;
}

.drag-area-active {
  background-color: rgb(238, 238, 238);
}
</style>